<template>
  <div class="bg">
    <div class="sum">全部(0)</div>
    <van-empty v-if="list == []"
      class="custom-image"
      image="https://img.yzcdn.cn/vant/custom-empty-image.png"
      description="暂无收藏"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-else>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class=" cardbox mb8" v-for="item in list" :key="item">
          <div class="card">
 
            <div class="tmail">
              <img src="../../assets/img/help_0.png" alt="" class="" />
              <div class="">
                <h4>硝苯地平控释片</h4>
                <p class="size">500g 直径10mm（药品规格、剂量）</p>
                <p>不支持7天无理由退换</p>
                <p><span>¥ 36.00</span> <em>x1</em></p>
              </div>
            </div>
            <div class="btnbox">
              <p></p>
                <div>
                    <span>取消收藏</span>
                    <span class="on">加入购物车</span>
                
                  </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  mounted() {},
  methods: {
    // 详情
    goinfo() {
      this.$router.push({ name: "orderinfo" });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style lang="less" scpoe>
.bg {
  padding: 16px;
  .sum{
    padding: 16px 0;
    text-align: left;
    color: #2d2d2d;
  }
  .cardbox {
    // padding: 16px;
    padding-bottom: 0;
    // background: #f5f5f5;
    .card {
      padding: 16px;
      background: #fff;
      text-align: left;
      .top {
        img {
          width: 30px;
          height: 30px;
          margin-right: 8px;
          margin-top: -4px;
        }
        span {
          font-size: 16px;
          color: #2d2d2d;
          vertical-align: top;
        }
      }
      .tmail {
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid #efefef;
        img {
          width: 80px;
          height: 80px;
          margin-right: 13px;
        }
        h4 {
          font-size: 16px;
          color: rgba(45, 45, 45, 1);
          font-weight: 700;
          margin-bottom: 6px;
        }
        div {
          width: 230px;
        }
        p {
          font-size: 12px;
          margin-bottom: 8px;
          span {
            color: rgba(251, 81, 81, 1);
            font-size: 14px;
          }
          em {
            float: right;
            font-style: normal;
          }
        }
        .size {
          font-size: 12px;
          color: rgba(29, 174, 252, 1);
          margin-bottom: 8px;
        }
      }
      .btnbox {
        display: flex;
        padding-top: 11px;
        justify-content: space-between;
        .daifahuo {
          color: rgba(29, 174, 252, 1);
        }
        div {
          span {
            display: inline-block;
            width: 68px;
            height: 32px;
            border: 1px solid #c2c2c2;
            border-radius: 8px;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            margin-left: 16px;
            &.on {
              color: rgba(29, 174, 252, 1);
              border: 1px solid rgba(29, 174, 252, 1);
            }
          }
        }
      }
    }
  }
}
</style>
